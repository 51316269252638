<template>
  <div>
    <Suggestion/>
  </div>
</template>

<script>
// @ is an alias to /src
import Suggestion from '@/components/Suggestion.vue'

export default {
  name: 'suggestion',
  components: {
    Suggestion
  }
}
</script>
