<template>
  <v-container>
    <h2>
      <span class="white--text"> Le Juke-box </span>
      <span class="red--text">{{ this.$route.params.bar | capitalize }}</span>
    </h2>
    <br />
    <v-card class="mx-auto" max-width="500">
      <div class="d-flex justify-center pt-2">
        <div class="disco"></div>
      </div>

      <v-card-title> Saisissez le titre d'une musique </v-card-title>

      <!-- <v-card-subtitle>  </v-card-subtitle> -->

      <v-card-text>
        <div style="height: 3.6em" class="d-flex">
          <v-text-field
            v-model="saisie"
            item-text="snippet.title"
            label="titre"
            class=""
            @keydown="get_google_suggestion"
          ></v-text-field>
          <div class="pt-7 pl-3">
            <v-btn small color="pink" class="white--text" @click="send_saisie"
              >Suggérer</v-btn
            >
          </div>
        </div>

        <div v-if="suggest_menu" class="suggestion_google">
          <v-card>
            <v-progress-circular
              v-if="timer"
              indeterminate
              color="red"
            ></v-progress-circular>
            <div v-else v-for="(item, i) in suggestGoogle.slice(0, 3)" :key="i">
              <v-list-item
                class="text-start"
                @click="select_value_of_suggestion(item)"
              >
                {{ item }}
              </v-list-item>
            </div>
          </v-card>
        </div>
        <!-- <v-list v-else-if="timer == false && selectedSuggestion == false">
          <v-list-item-group>
            <v-list-item v-for="(item, i) in suggestGoogle.slice(0, 3)" :key="i">
              <v-list-item-content>
                <v-list-item-title
                  @click="select_value_of_suggestion(item)"
                  v-text="item"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ text_snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "Suggestion",

  data() {
    return {
      videos: [],
      videos_selected: [],
      saisie: "",
      text_snackbar: "",
      snackbar: false,
      suggestGoogle: [],
      timer: false,
      selectedSuggestion: false,
      wait_for_suggest: false,
      suggest_menu: true,
    };
  },
  methods: {
    send_saisie() {
      if (!this.$cookie.get("wait")) {
        if (this.saisie.length < 1) {
          alert("Veuillez saisir une musique");
        } else if (this.saisie.length > 40) {
          alert("Votre saisie est trop longue");
        } else {
          this.suggest_menu = false;
          axios
            .post(this.$store.state.url + "/suggest", {
              bar_id: this.$route.params.bar,
              musique: this.saisie,
              uuid: this.$cookie.get("uuid"),
            })
            .then((response) => {
              if (response.data.code == 200) {
                this.text_snackbar = "Suggestion envoyée !";
                this.snackbar = true;

                this.send_socket();
                // this.wait_for_suggest = true;
                // this.wait_for_suggest = setTimeout(() => {
                //   this.wait_for_suggest = false;
                //   console.log("c'est bon");
                // }, 300000);

                this.$cookie.set("wait", true, {
                  expires: "5m",
                });
              } else {
                this.text_snackbar = "Erreur d'envoie";
                this.snackbar = true;
              }
            })
            .catch((error) => {
              this.text_snackbar = "Erreur d'envoie";
              this.snackbar = true;
              console.log(error);
            });
        }
      } else {
        this.text_snackbar =
          "Veuillez patienter pour effectuer une nouvelle suggestion...";
        this.snackbar = true;
      }
    },
    send_socket() {
      let suggestion = {
        bar_nom: this.$route.params.bar,
        musique: this.saisie,
      };
      this.$socket.emit("suggestion_socket", suggestion);
      this.saisie = "";
    },
    check_params() {
      axios
        .post(this.$store.state.url + "/check_bar", {
          bar_id: this.$route.params.bar,
        })
        .then((response) => {
          if (response.data.code != 200) {
            this.$store.state.authorize = false;
            this.$router.push("/NotFound");
          } else {
            this.$store.state.authorize = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    get_google_suggestion() {
      this.suggest_menu = true;
      if (this.timer) {
        null;
      } else {
        if (this.saisie.length > 1) {
          this.timer = setTimeout(() => {
            this.selectedSuggestion = false;

            axios
              .get(this.$store.state.url + "/suggestGoogle", {
                params: {
                  suggestGoogle: this.saisie,
                },
              })
              .then((response) => {
                this.suggestGoogle = response.data[1];
              })
              .catch((error) => {
                console.log(error);
              });
            this.timer = true;
            this.timer = setTimeout(() => {
              this.timer = false;
            }, 5000);
            this.timer = false;
          }, 3000);
        }
      }
    },
    select_value_of_suggestion(item) {
      this.saisie = item;
      this.selectedSuggestion = true;
      this.suggest_menu = false;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  created() {
    this.check_params();
  },
};
</script>
<style scoped>
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 25px !important;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.div_enseinte {
  width: 6em;
}
.enceinte {
  width: 13em;
}
.vibrations {
  height: 50%;
  animation-name: vibration_animation;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
}
.vibrations2 {
  height: 50%;
  animation-name: vibration_animation;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  transform: scaleX(-1);
}
@keyframes vibration_animation {
  from {
    width: 50%;
  }
  to {
    width: 65%;
  }
}
</style>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
}

.suggestion_google {
  padding-right: 7.4em;
  padding-bottom: 8em;
}
</style>
<style lang="scss" scoped>
/**
Disque jocket
 */
//// settings
$font-size: 20px; // base size
$size: 10em;
$background-color: #eaeaee;
$rpm: 33.5;
$center-size: 30%;

//colors
$highlight-color: #eef;
$print-white: #dde;
$print-1: rgb(77, 67, 145);
$print-2: rgb(207, 75, 145);

//// here be dragons
// animation duration
$t: 60s / $rpm;

// create grooves
$grooves: ();
$grooves-loop-size: 70px;
$grooves-steps: 59;
@if ($grooves-steps % 2 == 0) {
  $grooves-steps: $grooves-steps + 1;
}
$grooves-step-size: $grooves-loop-size/$grooves-steps;
$grooves-color: black;
$grooves-variation: 0.3;
$grooves-max-transparency: 0.5;

@for $i from 1 through $grooves-steps {
  $step: $grooves-step-size * $i;
  $transparency: 1;
  $cur-variation: random() * $grooves-variation;
  $transparency: $transparency - $cur-variation;
  @if ($i % 2==1) {
    $transparency: 1-$transparency;
  }
  $transparency: 1- ((1-$transparency) * $grooves-max-transparency);

  $grooves: append(
    $grooves,
    (transparentize($grooves-color, $transparency) $step),
    comma
  );
}

// create tracks
$tracks: ();
$tracks-num: 7;
$tracks-avail-size: 65%;
$tracks-start: 30%;
$track-size: $tracks-avail-size/$tracks-num;
$track-variation: 3%;
$track-ridge-size: 1%;
$track-ridge-color: rgba(0, 0, 0, 0.3);
$track-ridge-blur: 0.2%;
@for $i from 1 through $tracks-num {
  $cur-pos: $tracks-start + ($i * $track-size);
  $cur-pos: $cur-pos + (random() * $track-variation);
  $tracks: append(
    $tracks,
    (transparentize($track-ridge-color, 1) $cur-pos - $track-ridge-blur),
    comma
  );
  $tracks: append($tracks, ($track-ridge-color $cur-pos), comma);
  $cur-pos: $cur-pos + $track-ridge-size;
  $tracks: append($tracks, ($track-ridge-color $cur-pos), comma);
  $tracks: append(
    $tracks,
    (transparentize($track-ridge-color, 1) $cur-pos + $track-ridge-blur),
    comma
  );
}

// animations
@keyframes wiggle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes zoom {
  0% {
    opacity: 0.5;
    transform: scale(1.4);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2) rotate(180deg);
  }
  100% {
    opacity: 0.5;
    transform: scale(1) rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background: $background-color;
  font-size: $font-size;
}
.disco {
  // position:absolute;
  // left:50%;
  // top:50%;
  // margin-left: -$size/2;
  // margin-top: -$size/2;
  width: $size;
  height: $size;

  background: 
    // dark areas on both ends
    radial-gradient(
      circle closest-side,
      rgba(0, 0, 0, 1) 35%,
      rgba(0, 0, 0, 0) 35.5%,
      rgba(0, 0, 0, 0) 96%,
      rgba(0, 0, 0, 1) 96.5%
    ),
    // tracks
    radial-gradient(circle closest-side, $tracks),
    // highlight
    conic-gradient(
        black 40deg,
        $highlight-color 42deg,
        black 44deg,
        black 219deg,
        $highlight-color 222deg,
        $highlight-color 223deg,
        black 228deg
      ),
    // grooves
    repeating-radial-gradient($grooves),
    // weak lighting
    conic-gradient(
        transparentize(white, 1) 80deg,
        transparentize(white, 0.96) 90deg,
        transparentize(white, 1) 95deg,
        transparentize(white, 1) 260deg,
        transparentize(white, 0.96) 270deg,
        transparentize(white, 1) 285deg
      ),
    // strong lighting
    conic-gradient(
        transparentize(white, 1),
        transparentize(white, 0.78) 20deg,
        transparentize(white, 0.71) 40deg,
        transparentize(white, 1) 70deg,
        transparentize(white, 1) 180deg,
        transparentize(white, 0.82) 200deg,
        transparentize(white, 0.85) 210deg,
        transparentize(white, 1) 250deg
      ),
    black;
  background-blend-mode: normal, normal, color-dodge, normal, normal;
  animation: wiggle 30ms linear infinite alternate;
  border-radius: 100%;
  overflow: hidden;

  // zoom anim thing
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-radial-gradient($grooves);
    border-radius: 100%;
    animation: zoom $t linear infinite;
  }

  // inner label thing
  &::after {
    content: "Jukes";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: $center-size;
    height: $center-size;
    margin-left: -$center-size/2;
    margin-top: -$center-size/2;
    border-radius: 100%;
    color: $print-white;
    text-align: center;
    line-height: 3em;
    font-weight: bold;
    background: radial-gradient(
        circle closest-side,
        $background-color 8%,
        transparentize($background-color, 1) 9%,
        transparent 85%,
        transparent 90%
      ),
      conic-gradient($print-1 0%, $print-1 0 0%, $print-2 0);
    background-size: 100%, 3em 3em;
    animation: spin $t linear infinite;
  }
}
</style>

